import {
  getUnifiedString,
} from "@vsimple/vsimple-ui"

export default function userResponseMapper(response) {

  return response.map((user) => {
    return {
      ...user.account,
      ...user.companyAccount,
      id: user.account.id,
      accountId: user.companyAccount.id,
      tags: user.tags,
    }
  }).filter((user) => Object.keys(user).length).map((user) => {
    return {
      accountIdValue: String(user.accountId),
      value: String(user.id),
      label: getUnifiedString([user.firstName, user.lastName]),
      status: user.status,
      avatar: user.avatar,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      initials: String(user.firstName)[0].toUpperCase() + String(user.lastName)[0].toUpperCase(),
      isActive: user.isActive,
    }
  })
}
